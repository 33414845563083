// dnd-system/ContainerContext.jsx
import React, { createContext, useMemo } from 'react';

export const ContainerContext = createContext(null);

/**
 * ContainerProvider
 * Provides items and onChange callback from a DropableContainer to DraggableItems.
 */
export const ContainerProvider = ({ items, onChange, children }) => {
  const value = useMemo(() => ({ items, onChange }), [items, onChange]);
  return <ContainerContext.Provider value={value}>{children}</ContainerContext.Provider>;
};
