import React, { useEffect, useRef, useState } from 'react';
import './FormFileBlock.css';
import { useParams } from 'react-router-dom';
function FormFileBlock(props) {
  let para = useParams();
  let LocaleByPage = 'unknown';
  let [catalogstring, setcatalogstring] = useState('');
  let [pagetranslation, setpagetranslation] = useState({ data: [] });
  if (para.count) {
    LocaleByPage = para.count;
  }
  const fileInputRef = useRef(null);
  const fileInputRefLetter = useRef(null);
  const fileInputRefOthers = useRef(null);
  const fileInputRefZeugnis = useRef(null);
  let arrla1 = '';
  let la;
  let arrla;
  let param = useParams();
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;

  const requestTextOptions2 = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=FormTranslations',
      requestTextOptions2,
    )
      .then((res) => res.json())
      .then((json) => {
        setpagetranslation(json);
      })
      .then(() => catalogselect());

    if (props.type == 'training') {
      document.querySelector('.FormFileBlock-Textinputs').style = 'justify-content: right;';
      document.querySelector('.FormFileBlock-Headline').style = 'justify-content: right;';
      document.querySelector('.FormFileBlock-CommentSection').style = 'justify-content: right;';
    }
    if (props.type == 'job') {
      document.querySelector('.FormFileBlock-Textinputs').style = 'justify-content: right;';
      document.querySelector('.FormFileBlock-Headline').style = 'justify-content: right;';
      document.querySelector('.FormFileBlock-Fileupload').style = 'justify-content: right;';
      document.querySelector('.FormFileBlock-CommentSection').style = 'justify-content: right;';
    }
    if (props.type == 'prefix') {
      if (document.querySelector('#FormFileBlock-Headline')) {
        document.querySelector('#FormFileBlock-Headline').style = 'Font-size:24px !important;';
      }
    }
    if (props.type == 'catalog') {
      if (document.querySelector('.FormFileBlock-warp')) {
        document.querySelector('.FormFileBlock-warp').style = 'margin-top: 120px;';
      }
    }

    if (props.color == 'grey') {
      if (document.querySelector('.FormFileBlock-warp')) {
        document.querySelector('.FormFileBlock-warp').style = 'background-color: #f2f3f4 !important;';
        document.querySelector('.FormFileBlock-warp').classList.add('greyForm');
      }
    }
  }, []);

  // Function to handle the file upload
  function saveConfig() {
    let file = '';
    let fileLetter = '';
    let fileCertificates = '';
    let fileOthers = '';
    if (fileInputRef.current) {
      file = fileInputRef.current.files[0];
    }
    if (fileInputRefLetter.current) {
      fileLetter = fileInputRefLetter.current.files[0];
    }
    if (fileInputRefOthers.current) {
      fileCertificates = fileInputRefOthers.current.files[0];
    }
    if (fileInputRefZeugnis.current) {
      fileOthers = fileInputRefZeugnis.current.files[0];
    }

    let Errorwarning = '';
    let warning = document.getElementById('docsError');
    let FirstName = document.getElementById('FormFileBlock-FirstName').value;
    let LastName = document.getElementById('FormFileBlock-LastName').value;
    let Email = document.getElementById('FormFileBlock-eMail').value;
    let City = document.getElementById('FormFileBlock-City').value;
    let Phone = document.getElementById('FormFileBlock-PhoneNumber').value;
    let Salutation = document.getElementById('FormFileBlock-Salutation').value;

    let PostCode = '';
    let Country = '';
    let Street = '';
    let DateOfBirth = '';
    let CustomerNumber = '';
    let CompanyName = '';
    let Comment = '';
    let CatalogSelection = '';
    let SlugId = '';
    let Joblocation = '';
    let SalaryExpectations = '';
    let FirstLanguage = '';
    let EarliestStartDate = '';
    let LearnAboutJob = '';
    let EnglishSkills = '';
    let Channel = '';

    let Trainingsdate = '01/02/03';
    let Trainingsmail = 'digital@q-railing.com';
    let Trainingcomment = '';
    let Trainingsverteiler = 'Emmerich';
    if (props.trainingsmail) {
      Trainingsmail = props.trainingsmail;
    }

    if (props.trainingsverteiler) {
      Trainingsverteiler = props.trainingsverteiler;
    }
    if (Trainingsverteiler == null) {
      Trainingsverteiler = 'Emmerich';
    }

    if (props.trainingsdate) {
      Trainingsdate = props.trainingsdate;
    }

    if (props.type == 'training') {
      CustomerNumber = document.getElementById('FormFileBlock-CustomerNumber').value;
      CompanyName = document.getElementById('FormFileBlock-CompanyName').value;
      Comment = document.getElementById('FormFileBlock-Comment').value;
      Country = document.getElementById('FormFileBlock-Country').value;
      PostCode = document.getElementById('FormFileBlock-PostCode').value;
      Street = document.getElementById('FormFileBlock-Street').value;
      Trainingcomment = document.getElementById('FormFileBlock-TrainingComment').value;
    }

    if (props.type == 'prefix') {
      Comment = document.getElementById('FormFileBlock-Comment').value;
      Country = document.getElementById('FormFileBlock-Country').value;
      PostCode = document.getElementById('FormFileBlock-PostCode').value;
      Street = document.getElementById('FormFileBlock-Street').value;
    }
    if (props.type == 'roadshow') {
      Comment = document.getElementById('FormFileBlock-Comment').value;
      Country = document.getElementById('FormFileBlock-Country').value;
      PostCode = document.getElementById('FormFileBlock-PostCode').value;
      Street = document.getElementById('FormFileBlock-Street').value;
    }

    if (props.type == 'catalog') {
      CatalogSelection = catalogstring;
      PostCode = document.getElementById('FormFileBlock-PostCode').value;
      Street = document.getElementById('FormFileBlock-Street').value;
      Comment = document.getElementById('FormFileBlock-Comment').value;
      CompanyName = document.getElementById('FormFileBlock-CompanyName').value;
      Country = document.getElementById('FormFileBlock-Country').value;
    }

    if (props.type == 'job') {
      if (document.getElementById('FormFileBlock-JobStartdate')) {
        EarliestStartDate = document.getElementById('FormFileBlock-JobStartdate').value;
      }
      /*Joblocation = document.getElementById('FormFileBlock-JobLocation').value;*/

      FirstLanguage = document.getElementById('FormFileBlock-FirstLanguage').value;
      /*if (props.jobtype != 'Ausbildung') {
        SalaryExpectations = document.getElementById('FormFileBlock-SalaryExpectations').value;
      }*/
      EnglishSkills = document.getElementById('FormFileBlock-EnglishSkills').value;
      PostCode = document.getElementById('FormFileBlock-PostCode').value;
      Street = document.getElementById('FormFileBlock-Street').value;
      Comment = document.getElementById('FormFileBlock-Comment').value;
      Country = document.getElementById('FormFileBlock-Country').value;
      Channel = document.getElementById('FormFileBlock-Channel').value;
      DateOfBirth = document.getElementById('FormFileBlock-BirthDate').value;
    }

    //Normal sanit
    if (!file && props.file != 'nofile') {
      Errorwarning = 'Please add a your documents as a PDF';
      warning.innerText = Errorwarning;
      return;
    }
    if (Email.length < 1) {
      Errorwarning = 'Please enter an e-mail';
      warning.innerText = Errorwarning;
      return;
    }

    if (Phone.length < 1) {
      Errorwarning = 'Please enter your phone number';
      warning.innerText = Errorwarning;
      return;
    }
    if (!Email.match('@') || !Email.match('.')) {
      Errorwarning = 'Please enter a valid e-mail';
      warning.innerText = Errorwarning;
      return;
    }

    if (City.length < 1) {
      Errorwarning = 'Please enter a city';
      warning.innerText = Errorwarning;
      return;
    }
    if (PostCode.length < 1) {
      Errorwarning = 'Please enter your postcode';
      warning.innerText = Errorwarning;
      return;
    }

    //specific sanit
    if (props.type == 'training') {
      if (LastName.length < 1 || FirstName.length < 1) {
        Errorwarning = 'Please enter your name';
        warning.innerText = Errorwarning;
        return;
      }
    }

    if (props.type == 'catalog') {
      if (CatalogSelection.length < 1) {
        Errorwarning = 'Please select a catalog';
        warning.innerText = Errorwarning;
        return;
      }
    }

    if (props.type == 'job') {
      if (FirstLanguage.length < 1) {
        Errorwarning = 'Please select a first language';
        warning.innerText = Errorwarning;
        return;
      }
      if (LastName.length < 1 || FirstName.length < 1) {
        Errorwarning = 'Please enter your name';
        warning.innerText = Errorwarning;
        return;
      }
      if (EnglishSkills.length < 1) {
        Errorwarning = 'Please give an estimate of your english knowledge';
        warning.innerText = Errorwarning;
        return;
      }
      if (Street.length < 1) {
        Errorwarning = 'Please enter your address';
        warning.innerText = Errorwarning;
        return;
      }
      if (EnglishSkills.length < 1) {
        Errorwarning = 'Please give an estimate of your english knowledge';
        warning.innerText = Errorwarning;
        return;
      }
      if (EnglishSkills.length < 1) {
        Errorwarning = 'Please give an estimate of your english skills';
        warning.innerText = Errorwarning;
        return;
      }
      /*if (Joblocation.length < 1) {
        Errorwarning = 'Please give the Q-railing location you apply to';
        warning.innerText = Errorwarning;
        return;
      }*/
      if (DateOfBirth.length < 1) {
        Errorwarning = 'Please give your date of birth';
        warning.innerText = Errorwarning;
        return;
      }
    }

    if (file) {
      if (!file.name.match('.pdf') && props.file != 'nofile') {
        Errorwarning = 'Please upload the documents in pdf-format';
        warning.innerText = Errorwarning;
        return;
      }
    }

    if (props.slugdata) {
      SlugId = props.slugdata;
    }
    if (props.jobtitle) {
      SlugId = props.jobtitle;
    }

    Errorwarning = '';
    warning.innerText = Errorwarning;
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const timestamp = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    let PrivacyPolicyWord = 'Yes';
    if (file) {
      const formData = new FormData();
      formData.append('files', file, 'CV_' + FirstName + '_' + LastName + '_' + timestamp); // Use "files" as the key
      const requestOptions = {
        method: 'POST',
        body: formData,
      };
      fetch('https://walrus-app-tp3tp.ondigitalocean.app/api/upload/', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log('File uploaded:', data);
          // Handle success or further actions
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
          // Handle error
        });
    }
    if (fileCertificates) {
      const formData = new FormData();
      formData.append('files', fileCertificates, 'Certificates_' + FirstName + '_' + LastName + '_' + timestamp); // Use "files" as the key
      const requestOptions = {
        method: 'POST',
        body: formData,
      };
      fetch('https://walrus-app-tp3tp.ondigitalocean.app/api/upload/', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log('File uploaded:', data);
          // Handle success or further actions
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
          // Handle error
        });
    }
    if (fileLetter) {
      const formData = new FormData();
      formData.append('files', fileLetter, 'Letter_' + FirstName + '_' + LastName + '_' + timestamp); // Use "files" as the key
      const requestOptions = {
        method: 'POST',
        body: formData,
      };
      fetch('https://walrus-app-tp3tp.ondigitalocean.app/api/upload/', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log('File uploaded:', data);
          // Handle success or further actions
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
          // Handle error
        });
    }
    if (fileOthers) {
      const formData = new FormData();
      formData.append('files', fileOthers, 'Others_' + FirstName + '_' + LastName + '_' + timestamp); // Use "files" as the key
      const requestOptions = {
        method: 'POST',
        body: formData,
      };
      fetch('https://walrus-app-tp3tp.ondigitalocean.app/api/upload/', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log('File uploaded:', data);
          // Handle success or further actions
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
          // Handle error
        });
    }

    if (file || fileLetter || fileCertificates || fileOthers) {
      let formData = new FormData();

      // Combine all files into a single FormData object

      let fileRefs = [fileInputRef, fileInputRefLetter, fileInputRefZeugnis, fileInputRefOthers];
      let allFiles = fileRefs.filter((ref) => ref.current);
      allFiles.forEach((fileRef) => {
        let files = fileRef.current.files;
        for (let i = 0; i < files.length; i++) {
          formData.append('upload', files[i]);
        }
      });
      formData.append('FirstName', FirstName);
      formData.append('Email', Email);
      formData.append('LastName', LastName);
      formData.append('Salutation', Salutation);
      formData.append('PostCode', PostCode);
      formData.append('City', City);
      formData.append('Country', Country);
      formData.append('Phone', Phone);
      formData.append('Location', Joblocation);
      formData.append('Position', SlugId);
      formData.append('SalaryExpectations', SalaryExpectations);
      formData.append('FirstLanguage', FirstLanguage);
      formData.append('EarliestStartDate', EarliestStartDate);
      formData.append('LearnAboutJob', LearnAboutJob);
      formData.append('EnglishKnowledge', EnglishSkills);
      formData.append('Comment', Comment);
      formData.append('StreetNr', Street);
      formData.append('DateOfBirth', DateOfBirth);
      formData.append('Channel', Channel);
      let token = process.env.REACT_APP_PUBLIC_KEY2;

      //Email check http://localhost:1337/ otherwise https://strapi-qrailing.azurewebsites.net/
      fetch('https://strapi-qrailing.azurewebsites.net/api/documentupload', {
        method: 'POST',
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.log(error));

      if (props.personioid) {
        formData.append('PersonioId', props.personioid);
        fetch('https://strapi-qrailing.azurewebsites.net/api/personioapi', {
          method: 'POST',
          body: formData,
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => response.json())
          .then((data) => console.log(data))
          .catch((error) => console.log(error));
      }
    }
    let reqbody = JSON.stringify({
      data: {
        Position: SlugId,
        Email: Email,
        Location: Joblocation,
        FirstName: FirstName,
        LastName: LastName,
        PostCode: PostCode,
        Country: Country,
        City: City,
        Phone: Phone,
        SalaryExpectations: SalaryExpectations,
        FIrstLanguage: FirstLanguage,
        EarliestStartDate: EarliestStartDate,
        LearnAboutJob: LearnAboutJob,
        EnglishKnowledge: EnglishSkills,
        DateOfBirth: DateOfBirth,
        Channel: Channel,
        Anrede: Salutation,
        PrivacyPolicy: PrivacyPolicyWord,
      },
    });

    let trainingsbody = JSON.stringify({
      data: {
        Email: Email,
        FirstName: FirstName,
        LastName: LastName,
        PostCode: PostCode,
        Country: Country,
        Locale: LocaleByPage,
        City: City,
        Phone: Phone,
        Street: Street,
        CompanyName: CompanyName,
        Salutation: Salutation,
        OtherParticipants: Comment,
        CustomerId: CustomerNumber,
        RequestID: SlugId,
        MailVerteiler: Trainingsverteiler,
        Date: Trainingsdate,
        MailContact: Trainingsmail,
        Comments: Trainingcomment,
      },
    });

    let catalogbody = JSON.stringify({
      data: {
        Mail: Email,
        FirstName: FirstName,
        LastName: LastName,
        PostCode: PostCode,
        Country: Country,
        City: City,
        Phone: Phone,
        State: LocaleByPage,
        Street: Street,
        CompanyName: CompanyName,
        Salutation: Salutation,
        CatalogSelection: catalogstring,
        Comments: Comment,
      },
    });

    let prefixbody = JSON.stringify({
      data: {
        Email: Email,
        FirstName: FirstName,
        LastName: LastName,
        PostCode: PostCode,
        Country: Country,
        City: City,
        Phone: Phone,
        State: LocaleByPage,
        StreetNr: Street,
        Salutation: Salutation,
        Comments: Comment,
        Locale: LocaleByPage,
        Title: 'Sprecials',
      },
    });
    //decide where to send those things

    let fetchurl = 'https://strapi-qrailing.azurewebsites.net/api/job-applications/';

    let requestTextOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: reqbody,
    };

    if (props.type == 'job') {
      fetchurl = 'https://strapi-qrailing.azurewebsites.net/api/job-applications/';
      requestTextOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: reqbody,
      };
    }
    if (props.type == 'prefix') {
      fetchurl = 'https://strapi-qrailing.azurewebsites.net/api/specials-requests/';
      requestTextOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: prefixbody,
      };
    }

    if (props.type == 'roadshow') {
      fetchurl = 'https://strapi-qrailing.azurewebsites.net/api/roadshow-requests/';
      requestTextOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: prefixbody,
      };
    }

    if (props.type == 'training') {
      fetchurl = 'https://strapi-qrailing.azurewebsites.net/api/training-requests/';
      requestTextOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: trainingsbody,
      };
    }

    if (props.type == 'catalog') {
      fetchurl = 'https://strapi-qrailing.azurewebsites.net/api/catalog-requests/';
      requestTextOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: catalogbody,
      };
    }

    fetch(fetchurl, requestTextOptions)
      .then((response) => response.json())
      .then(
        setTimeout(() => {
          console.log('###########sleep#############');
        }, 2000),
      )
      .then((data) => this.setState({ postId: data.id }));

    //reset values to Zero
    document.getElementById('ApplyButton').style = 'display:none';
    document.getElementById('docsError').innerText = 'Thank you for your inquiry';
    if (props.type == 'prefix') {
      document.getElementById('docsError').innerText = 'Thank you for your inquiry';
    }
    if (props.type == 'job') {
      document.getElementById('docsError').innerText = 'Thank you for sending us your application';
    }

    if (props.type != 'job') {
      document.getElementById('docsError').innerText =
        pagetranslation.data.length > 0
          ? pagetranslation.data[0].attributes.PageTranslations.data[31]
          : 'Thank you for your request!';
    }

    if (document.getElementById('FormFileBlock-Document-label')) {
      document.getElementById('FormFileBlock-Document-label').style = 'display:none';
      if (document.getElementById('FormFileBlock-Document-CV-text')) {
        document.getElementById('FormFileBlock-Document-CV-text').style = 'display:none';
      }
    }
    if (document.querySelector('.FormFileBlock-Documents-Others')) {
      document.querySelector('.FormFileBlock-Documents-Others').style = 'display:none';
      if (document.getElementById('FormFileBlock-Document-Others-text')) {
        document.getElementById('FormFileBlock-Document-Others-text').style = 'display:none';
      }
    }

    document.getElementById('FormFileBlock-FirstName').value = '';
    document.getElementById('FormFileBlock-eMail').value = '';
    document.getElementById('FormFileBlock-City').value = '';
    document.getElementById('FormFileBlock-LastName').value = '';
    document.getElementById('FormFileBlock-PhoneNumber').value = '';
    document.getElementById('docsError').style =
      'color:darkgreen;font-size:24px;margin-top:32px;margin-bottom:-32px;font-weight:600';
  }

  function catalogselect() {
    if (pagetranslation.data.length > 0) {
      return (
        <>
          <div id="FormFileBlock-CatalogChoices">
            {pagetranslation.data[0].attributes.FreeData1.data.map((item) => {
              return (
                <>
                  <label>
                    <input
                      onChange={(e) => {
                        e.target.checked
                          ? setcatalogstring(catalogstring + '|' + item + '|')
                          : setcatalogstring(catalogstring.replace('|' + item + '|', ''));
                      }}
                      type="checkbox"
                    />
                    {item}
                  </label>
                </>
              );
            })}
          </div>
        </>
      );
    }
  }

  return (
    <>
      <div className="FormFileBlock-warp">
        <div className="FormFileBlock-semiwrap">
          <div className="FormFileBlock-Headline">
            <div className="FormFileBlock-Headline-Inner">
              <h3 id="FormFileBlock-Headline">
                {pagetranslation.data.length > 0 ? pagetranslation.data[0].attributes.FreeTitle1 : 'Personal details'}
              </h3>
            </div>
          </div>
          <form
            onSubmit={(e) => e.preventDefault()}
            className="FormFileBlock-inner"
            id={
              props.type == 'prefix'
                ? 'prefix-form'
                : props.type == 'training'
                  ? 'training-form'
                  : props.type == 'job'
                    ? 'job-form'
                    : props.type == 'catalog-form'
                      ? 'catalog-form'
                      : props.type == 'roadshow'
                        ? 'roadshow-form'
                        : 'File-form'
            }
          >
            <div className="FormFileBlock-Textinputs">
              <div className="FormFileBlock-Textinputs-inner">
                <div>
                  {(props.type == 'training' || props.type == 'catalog') && (
                    <>
                      <label>
                        {' '}
                        <span>
                          {pagetranslation.data.length > 0
                            ? pagetranslation.data[0].attributes.PageTranslations.data[2]
                            : 'Company Name'}
                          *
                        </span>{' '}
                        <input id="FormFileBlock-CompanyName" type="text" />
                      </label>
                    </>
                  )}

                  {param.count != 'da-dk' && (
                    <label>
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[3]
                          : 'Salutation'}
                      </span>
                      <div class="select-container">
                        <select id="FormFileBlock-Salutation">
                          <option>{'-'}</option>
                          <option>
                            {pagetranslation.data.length > 0
                              ? pagetranslation.data[0].attributes.PageTranslations.data[27]
                              : 'Mr'}
                          </option>
                          <option>
                            {pagetranslation.data.length > 0
                              ? pagetranslation.data[0].attributes.PageTranslations.data[28]
                              : 'Mrs/Ms'}
                          </option>
                          <option>
                            {pagetranslation.data.length > 0
                              ? pagetranslation.data[0].attributes.PageTranslations.data[29]
                              : 'Non binary'}
                          </option>
                        </select>
                      </div>
                    </label>
                  )}

                  <label>
                    {' '}
                    <span>
                      {pagetranslation.data.length > 0
                        ? pagetranslation.data[0].attributes.PageTranslations.data[5]
                        : 'Last Name'}
                      {props.type == 'job' && '*'}
                    </span>{' '}
                    <input id="FormFileBlock-LastName" type="text" />
                  </label>
                  <label>
                    {' '}
                    <span>
                      {pagetranslation.data.length > 0
                        ? pagetranslation.data[0].attributes.PageTranslations.data[11]
                        : 'Street+Number'}
                      *
                    </span>{' '}
                    <input id="FormFileBlock-Street" type="text" />
                  </label>
                  <label>
                    {' '}
                    <span>
                      {pagetranslation.data.length > 0
                        ? pagetranslation.data[0].attributes.PageTranslations.data[10]
                        : 'City'}
                      *
                    </span>{' '}
                    <input id="FormFileBlock-City" type="text" />
                  </label>

                  {props.type != 'catalog' && (
                    <label>
                      {' '}
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[13]
                          : 'Phone number'}
                        *
                      </span>{' '}
                      <input id="FormFileBlock-PhoneNumber" type="text" />
                    </label>
                  )}
                  {props.type == 'training' && (
                    <label>
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[7]
                          : 'Location'}
                      </span>
                      <div class="select-container">
                        <select id="FormFileBlock-Location">
                          <option>{'München'}</option>
                          <option>{'Emmerich'}</option>
                          <option>{'Road'}</option>
                        </select>
                      </div>
                    </label>
                  )}
                  {props.type == 'job' && (
                    <label>
                      {' '}
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[8]
                          : 'First Language'}
                        *
                      </span>{' '}
                      <input id="FormFileBlock-FirstLanguage" type="text" />
                    </label>
                  )}

                  {props.type == 'job' && (
                    <>
                      {/*<label>
                        {' '}
                        <span>
                          {pagetranslation.data.length > 0
                            ? pagetranslation.data[0].attributes.PageTranslations.data[1]
                            : 'Q-railing Standort'}
                          *
                        </span>{' '}
                        <input id="FormFileBlock-JobLocation" type="text" />
                      </label>*/}
                    </>
                  )}
                  {props.type == 'job' && (
                    <label>
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[16]
                          : 'Earliest start date'}
                        *
                      </span>{' '}
                      <input id="FormFileBlock-JobStartdate" type="date" />
                    </label>
                  )}
                </div>
                <div>
                  <label>
                    {' '}
                    <span>
                      {pagetranslation.data.length > 0
                        ? pagetranslation.data[0].attributes.PageTranslations.data[4]
                        : 'First Name'}
                      {props.type == 'job' && '*'}
                    </span>{' '}
                    <input id="FormFileBlock-FirstName" type="text" />
                  </label>

                  {props.type == 'job' && (
                    <label>
                      {' '}
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[33]
                          : 'Date of birth'}
                        {props.type == 'job' && '*'}
                      </span>{' '}
                      <input id="FormFileBlock-BirthDate" type="date" />
                    </label>
                  )}

                  <label>
                    {' '}
                    <span>
                      {pagetranslation.data.length > 0
                        ? pagetranslation.data[0].attributes.PageTranslations.data[9]
                        : 'Postal code'}
                      *
                    </span>{' '}
                    <input id="FormFileBlock-PostCode" type="text" />
                  </label>
                  <label>
                    {' '}
                    <span>
                      {pagetranslation.data.length > 0
                        ? pagetranslation.data[0].attributes.PageTranslations.data[12]
                        : 'Country'}
                      *
                    </span>{' '}
                    <input id="FormFileBlock-Country" type="text" />
                  </label>
                  <label>
                    {' '}
                    <span>
                      {pagetranslation.data.length > 0
                        ? pagetranslation.data[0].attributes.PageTranslations.data[6]
                        : 'E-mail address'}
                      *{' '}
                    </span>
                    <input id="FormFileBlock-eMail" type="text" />
                  </label>
                  {props.type == 'catalog' && (
                    <label>
                      {' '}
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[13]
                          : 'Phone number'}
                        *
                      </span>{' '}
                      <input id="FormFileBlock-PhoneNumber" type="text" />
                    </label>
                  )}

                  {props.type == 'job' && props.jobtype != 'Ausbildung' && (
                    <>
                      {/*<label>
                      {' '}
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[14]
                          : 'Salary expectations'}
                        *
                      </span>{' '}
                      <input id="FormFileBlock-SalaryExpectations" type="text" />
                    </label>*/}
                    </>
                  )}
                  {props.type == 'job' && (
                    <label>
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[15]
                          : 'English skills'}
                      </span>
                      <div class="select-container">
                        <select id="FormFileBlock-EnglishSkills">
                          <option value="-">-</option>
                          <option value="beginner">Beginner</option>
                          <option value="elementary">Elementary</option>
                          <option value="intermediate">Intermediate</option>
                          <option value="advanced">Advanced</option>
                          <option value="proficient">Proficient</option>
                          <option value="native">Native Speaker</option>
                        </select>
                      </div>
                    </label>
                  )}

                  {props.type == 'job' && (
                    <label>
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[32]
                          : 'learned about the job from'}
                      </span>
                      <div class="select-container">
                        <select id="FormFileBlock-Channel">
                          {pagetranslation.data.length > 0 &&
                            pagetranslation.data[0].attributes.FreeData5.data.map((item) => {
                              return (
                                <>
                                  <option value={item}>{item}</option>
                                </>
                              );
                            })}
                        </select>
                      </div>
                    </label>
                  )}

                  {props.type == 'training' && (
                    <label>
                      {' '}
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[18]
                          : 'Customer-No.'}
                        *
                      </span>{' '}
                      <input id="FormFileBlock-CustomerNumber" type="text" />
                    </label>
                  )}

                  {props.type == 'training' && (
                    <label>
                      {' '}
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[23]
                          : 'Comment'}
                        *
                      </span>{' '}
                      <textarea
                        id="FormFileBlock-TrainingComment"
                        placeholder={
                          pagetranslation.data.length > 0
                            ? pagetranslation.data[0].attributes.PageTranslations.data[30]
                            : 'e.g Food allergies'
                        }
                        type="text"
                      />
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="FormFileBlock-CommentSection">
              <div className="FormFileBlock-CommentSection-inner">
                {props.type == 'training' && (
                  <label>
                    {' '}
                    <span>
                      {' '}
                      {pagetranslation.data.length > 0
                        ? pagetranslation.data[0].attributes.PageTranslations.data[24]
                        : 'Sonstige Teilnehmer'}
                    </span>{' '}
                    <textarea id="FormFileBlock-Comment" type="text" />
                  </label>
                )}
                {props.type != 'training' && (
                  <label>
                    {' '}
                    <span>
                      {' '}
                      {pagetranslation.data.length > 0
                        ? pagetranslation.data[0].attributes.PageTranslations.data[23]
                        : 'Comment'}
                    </span>{' '}
                    <textarea id="FormFileBlock-Comment" type="text" />
                  </label>
                )}
                {props.type == 'job' && (
                  <>
                    <label id="FormFileBlock-PrivacyPolicyLabel">
                      <span>
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[17]
                          : 'PrivacyPolicy'}
                        *
                      </span>
                      <div className="PrivacyJob">
                        <input id="FormFileBlock-PrivacyPolicy" type="checkbox" />
                        {pagetranslation.data.length > 0 && (
                          <p dangerouslySetInnerHTML={{ __html: pagetranslation.data[0].attributes.CustomText }} />
                        )}
                      </div>
                    </label>
                  </>
                )}
              </div>
            </div>

            {props.type == 'catalog' && <div className="FormFileBlock-CatalogSelection">{catalogselect()}</div>}
            <p id="docsError"></p>
            <div className="FormFileBlock-Fileupload">
              <div className="FormFileBlock-Fileupload-inner">
                <div className="FormFileBlock-Uploadfields">
                  {props.file != 'nofile' && (
                    <>
                      <label id="FormFileBlock-Document-label" for="FormFileBlock-Document-CV">
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[19]
                          : 'CV'}
                        *{' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#ffffff"
                          viewBox="0 0 256 256"
                        >
                          <path d="M240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H80a8,8,0,0,1,0,16H32v64H224V136H176a8,8,0,0,1,0-16h48A16,16,0,0,1,240,136ZM85.66,77.66,120,43.31V128a8,8,0,0,0,16,0V43.31l34.34,34.35a8,8,0,0,0,11.32-11.32l-48-48a8,8,0,0,0-11.32,0l-48,48A8,8,0,0,0,85.66,77.66ZM200,168a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z"></path>
                        </svg>
                        <input
                          accept=".pdf,image/png,image/jpeg"
                          onChange={() => {
                            document.getElementById('FormFileBlock-Document-CV-text').innerText =
                              fileInputRef.current.files[0].name;
                          }}
                          id="FormFileBlock-Document-CV"
                          type="file"
                          ref={fileInputRef}
                        />{' '}
                      </label>
                      <span id="FormFileBlock-Document-CV-text"></span>
                    </>
                  )}

                  {props.jobtype == 'Ausbildung' && (
                    <>
                      <label id="FormFileBlock-Document-label" for="FormFileBlock-Document-Letter">
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[20]
                          : 'Supporting Letter'}
                        *{' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#ffffff"
                          viewBox="0 0 256 256"
                        >
                          <path d="M240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H80a8,8,0,0,1,0,16H32v64H224V136H176a8,8,0,0,1,0-16h48A16,16,0,0,1,240,136ZM85.66,77.66,120,43.31V128a8,8,0,0,0,16,0V43.31l34.34,34.35a8,8,0,0,0,11.32-11.32l-48-48a8,8,0,0,0-11.32,0l-48,48A8,8,0,0,0,85.66,77.66ZM200,168a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z"></path>
                        </svg>
                        <input
                          accept=".pdf,image/png,image/jpeg"
                          onChange={() => {
                            document.getElementById('FormFileBlock-Document-Letter-text').innerText =
                              fileInputRefLetter.current.files[0].name;
                          }}
                          id="FormFileBlock-Document-Letter"
                          type="file"
                          ref={fileInputRefLetter}
                        />{' '}
                      </label>
                      <span id="FormFileBlock-Document-Letter-text"></span>
                    </>
                  )}

                  {props.jobtype == 'Ausbildung' && (
                    <>
                      <label id="FormFileBlock-Document-label" for="FormFileBlock-Document-Certificate">
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[21]
                          : 'Certificates'}
                        *{' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#ffffff"
                          viewBox="0 0 256 256"
                        >
                          <path d="M240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H80a8,8,0,0,1,0,16H32v64H224V136H176a8,8,0,0,1,0-16h48A16,16,0,0,1,240,136ZM85.66,77.66,120,43.31V128a8,8,0,0,0,16,0V43.31l34.34,34.35a8,8,0,0,0,11.32-11.32l-48-48a8,8,0,0,0-11.32,0l-48,48A8,8,0,0,0,85.66,77.66ZM200,168a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z"></path>
                        </svg>
                        <input
                          accept=".pdf,image/png,image/jpeg"
                          onChange={() => {
                            document.getElementById('FormFileBlock-Document-Certificate-text').innerText =
                              fileInputRefZeugnis.current.files[0].name;
                          }}
                          id="FormFileBlock-Document-Certificate"
                          type="file"
                          ref={fileInputRefZeugnis}
                        />{' '}
                      </label>
                      <span id="FormFileBlock-Document-Certificate-text"></span>
                    </>
                  )}

                  {props.file != 'nofile' && (
                    <>
                      <label
                        className="FormFileBlock-Documents-Others"
                        id="FormFileBlock-Document-label"
                        for="FormFileBlock-Document-Others"
                      >
                        {pagetranslation.data.length > 0
                          ? pagetranslation.data[0].attributes.PageTranslations.data[22]
                          : 'Others'}{' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#ffffff"
                          viewBox="0 0 256 256"
                        >
                          <path d="M240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H80a8,8,0,0,1,0,16H32v64H224V136H176a8,8,0,0,1,0-16h48A16,16,0,0,1,240,136ZM85.66,77.66,120,43.31V128a8,8,0,0,0,16,0V43.31l34.34,34.35a8,8,0,0,0,11.32-11.32l-48-48a8,8,0,0,0-11.32,0l-48,48A8,8,0,0,0,85.66,77.66ZM200,168a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z"></path>
                        </svg>
                        <input
                          accept=".pdf,image/png,image/jpeg"
                          onChange={() => {
                            document.getElementById('FormFileBlock-Document-Others-text').innerText =
                              fileInputRefOthers.current.files[0].name;
                          }}
                          id="FormFileBlock-Document-Others"
                          type="file"
                          ref={fileInputRefOthers}
                        />{' '}
                      </label>
                      <span id="FormFileBlock-Document-Others-text"></span>
                    </>
                  )}
                </div>
                {pagetranslation.data.length > 0 && (
                  <button id="ApplyButton" onClick={saveConfig}>
                    {props.type == 'job'
                      ? pagetranslation.data[0].attributes.PageTranslations.data[26]
                      : pagetranslation.data[0].attributes.PageTranslations.data[25]}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default FormFileBlock;
