// dnd-system/DraggableItem.jsx
import React, { useContext, useRef } from 'react';
import { DnDContext } from './drag-and-drop-context';
import { ContainerContext } from './drag-and-drop-container-context';

/**
 * DraggableItem
 * Represents a draggable item.
 * If the `item` prop is provided, this element is considered as an actual draggable item.
 * If no `item` prop is provided, this can be used as a wrapper or grouping element.
 * Events:
 * - onItemDragStart: fired when drag starts on this particular item.
 * - onItemDragEnd: fired when drag ends on this item.
 */
const DraggableItem = ({ item, children, onItemDragStart, onItemDragEnd }) => {
  const { setDraggedItem, setDraggedItemOrigin, onDragStart, onDragEnd } = useContext(DnDContext);
  const containerContext = useContext(ContainerContext);
  const itemRef = useRef(null);

  const handleDragStart = (e) => {
    if (item === undefined) {
      // Not an actual item, no drag should start
      e.preventDefault();
      return;
    }

    const rect = itemRef.current.getBoundingClientRect();

    // Create a shadow element that will be used as the drag image
    const clone = itemRef.current.cloneNode(true);
    clone.classList.add('shadow-element');
    clone.style.position = 'absolute';
    clone.style.top = '-9999px';
    clone.style.left = '-9999px';
    document.body.appendChild(clone);

    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setDragImage(clone, rect.width / 3, rect.height / 3);

    setDraggedItem({ data: item });
    if (containerContext) {
      setDraggedItemOrigin({ items: containerContext.items, onChange: containerContext.onChange });
    }

    // Fire global and item-level events
    onDragStart && onDragStart();
    onItemDragStart && onItemDragStart();

    setTimeout(() => {
      if (document.body.contains(clone)) {
        document.body.removeChild(clone);
      }
    }, 0);
  };

  const handleDragEnd = () => {
    // Fire global and item-level events
    onDragEnd && onDragEnd();
    onItemDragEnd && onItemDragEnd();
  };

  return (
    <div
      className="draggable-item"
      ref={itemRef}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
    </div>
  );
};

DraggableItem.displayName = 'DraggableItem';

export default DraggableItem;
