import './App.css';
import { Routes } from 'react-router-dom';
import './components/includes/Responsive.css';
import './components/KonfiguratorDetail.css';
import './components/includes/Header.css';
import React, { useEffect } from 'react';

import { RoutesComponent } from './utility/routing/routes-component';
import { MetaLocaleRoutes } from 'components/meta-tags/meta-locale-routes';
import { useLocale } from 'hooks/use-locale';
import { Utility } from 'utility/utility';

function App() {
  const locale = useLocale();

  useEffect(() => {
    // Define the font URL(s) from your S3 bucket
    const fontUrl = 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/Inter-Regular.ttf';

    // Create a link element for the font
    const fontLink = document.createElement('link');
    fontLink.href = fontUrl;
    fontLink.rel = 'stylesheet';

    // Append the link to the head of the document
    document.head.appendChild(fontLink);

    // Optionally, you can load multiple fonts by creating additional link elements

    return () => {
      // Clean up the link element when the component unmounts
      document.head.removeChild(fontLink);
    };
  }, []);

  /*useEffect(() => {
    if (!locale || Utility.isClientCrawler()) return;

    const lang = locale.split('-')[0];
    document.documentElement.lang = lang;

    const lastLocaleReset = window.localStorage.getItem('last-locale-reset');

    if (!lastLocaleReset || Number(lastLocaleReset) < 1737369686000) {
      Utility.fetchLocale().then((localeData) => {
        window.localStorage.setItem('last-locale-reset', Date.now());
        if (localeData === locale) return;
        const path = `/${localeData}/`;
        window.location.pathname = path;
      });
    }
  }, [locale]);*/

  return (
    <>
      <MetaLocaleRoutes />
      <Routes>{RoutesComponent()}</Routes>
    </>
  );
}

export default App;
