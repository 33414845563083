import './i18n-management.css';
import { useState, useEffect } from 'react';
import DragAndDropContext from 'shared/drag-and-drop/drag-and-drop-context';
import DraggableItem from 'shared/drag-and-drop/draggable-item';
import DropableContainer from 'shared/drag-and-drop/dropable-container';
import { joinUrl } from 'utility/utility';
import HeaderSlim from '../../components/includes/HeaderSlim';

const allLanguagesUrl = joinUrl(process.env.REACT_APP_SHOP_API_URL, 'i18n', 'languages');

export function I18nManagement() {
  const [isEditable, setIsEditable] = useState(false);
  const [search, setSearch] = useState('');
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [unsupportedLanguages, setUnsupportedLanguages] = useState([]);

  useEffect(() => {
    fetch(allLanguagesUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to fetch all languages');
      })
      .then((data) => {
        setSupportedLanguages(data.filter((x) => x.isSupported));
        setUnsupportedLanguages(data.filter((x) => !x.isSupported));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <HeaderSlim></HeaderSlim>
      <div className="i18n-management">
        <DragAndDropContext>
          <h1>I18n Management</h1>
          <h3>Drag and drop to reorder</h3>
          <div className="language-display">
            <div>
              <h3>Available Languages</h3>
              <DropableContainer items={unsupportedLanguages} onChange={setUnsupportedLanguages}>
                {unsupportedLanguages.map((language, index) => (
                  <DraggableItem key={index} item={language}>
                    {language.name}
                  </DraggableItem>
                ))}
              </DropableContainer>
            </div>
            <div>
              <h3>Supported Languages</h3>
              <DropableContainer items={supportedLanguages} onChange={setSupportedLanguages}>
                {supportedLanguages.map((language, index) => (
                  <DraggableItem key={index} item={language}>
                    {language.name}
                  </DraggableItem>
                ))}
              </DropableContainer>
            </div>
          </div>
        </DragAndDropContext>
      </div>
    </>
  );
}
