import React, { useEffect, useState } from 'react';
import { joinUrl } from 'utility/utility';
import './category-management.css';

const productCategoriesUrl = joinUrl(process.env.REACT_APP_SHOP_API_URL, 'product-categories');
const supportedLanguagesUrl = joinUrl(process.env.REACT_APP_SHOP_API_URL, 'i18n', 'supported-languages');

export function CategoryManagement() {
  const [productCategories, setProductCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategoryFilter, setSubCategoryFilter] = useState('');
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('en-GB');

  useEffect(() => {
    fetch(productCategoriesUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to fetch product categories');
      })
      .then((data) => {
        setProductCategories(data);
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(supportedLanguagesUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to fetch supported languages');
      })
      .then((data) => {
        console.log(data);

        setLanguages(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const subCategories = productCategories.filter(
      (x) =>
        x.parentId === (selectedCategory?.id ?? null) && x.translations.some((x) => x.name.includes(subCategoryFilter)),
    );

    setSubCategories(subCategories);
  }, [subCategoryFilter, productCategories, selectedCategory]);

  return (
    <div className="category-management">
      <div className="title">
        <h1>Category Management</h1>
        <div className="spacer"></div>
        <select
          className="language-select"
          value={`${selectedLanguage.isoCode} | ${selectedLanguage.name}`}
          onChange={(e) => setSelectedLanguage(languages.find((x) => x.isoCode === e.currentTarget.value))}
        >
          {languages.map((x) => (
            <option
              key={x.isoCode}
              value={x.isoCode}
            >{`${selectedLanguage.isoCode} | ${selectedLanguage.name}`}</option>
          ))}
        </select>
      </div>
      <div className="category-display">
        <div className="hierarchy-control">
          <div className="go-back"></div>
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Search"
              value={subCategoryFilter}
              onChange={(x) => setSubCategoryFilter(x.currentTarget.value)}
            />
          </div>
        </div>
        <div className="sub-categories">
          {subCategories.map((x) => (
            <div key={x.id} className="sub-category">
              {x.translations.find((x) => x.languageIsoCode === selectedLanguage)?.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
