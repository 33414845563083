// dnd-system/DragAndDropContext.jsx
import React, { createContext, useMemo, useState } from 'react';

export const DnDContext = createContext();

/**
 * DragAndDropContext
 * Provides a global context for the drag and drop system.
 * Holds the currently dragged item, its origin, and preview information.
 * Fires global events if provided by props.
 */
const DragAndDropContext = ({ children, onDragStart, onDragEnd, onDrop }) => {
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedItemOrigin, setDraggedItemOrigin] = useState(null);
  const [previewInfo, setPreviewInfo] = useState(null);

  const value = useMemo(
    () => ({
      draggedItem,
      setDraggedItem,
      draggedItemOrigin,
      setDraggedItemOrigin,
      previewInfo,
      setPreviewInfo,
      onDragStart,
      onDragEnd,
      onDrop,
    }),
    [draggedItem, draggedItemOrigin, previewInfo, onDragStart, onDragEnd, onDrop],
  );

  return (
    <DnDContext.Provider value={value}>
      <div className="drag-and-drop-context">{children}</div>
    </DnDContext.Provider>
  );
};

export default DragAndDropContext;
