//eslint-disable-next-line
import { useLocation, useParams } from 'react-router-dom';
//eslint-disable-next-line
import { useEffect, useState } from 'react';
//eslint-disable-next-line

//eslint-disable-next-line
import { useRef } from 'react';
//eslint-disable-next-line
import '../Mainpage.css';
import './ProductCategoryGrid.css';

import '../Systems.css';

import Footernew from './Footernew';

import NewsletterNew from './NewsletterNew';

import HeaderSlim from './HeaderSlim';
import '../CategoryGridPage.css';
function ProductCategoryGrid(props) {
  //eslint-disable-next-line
  let [activetoggle, setactivetoggle] = useState('all');
  const [ToggleIcon, setToggleIcon] = useState(false);

  const translationsJson = {
    'da-dk': ['Vis alle', 'Filtrer resultater', 'Vis mindre'], // Danish (Denmark)
    'de-at': ['Alle anzeigen', 'Ergebnisse filtern', 'Weniger anzeigen'], // German (Austria)
    'de-ch': ['Alle anzeigen', 'Ergebnisse filtern', 'Weniger anzeigen'], // German (Switzerland)
    'de-de': ['Alle anzeigen', 'Ergebnisse filtern', 'Weniger anzeigen'], // German (Germany)
    en: ['Show all', 'Filter results', 'Show less'], // English (Default)
    'en-ca': ['Show all', 'Filter results', 'Show less'], // English (Canada)
    'en-gb': ['Show all', 'Filter results', 'Show less'], // English (UK)
    'en-ie': ['Show all', 'Filter results', 'Show less'], // English (Ireland)
    'en-us': ['Show all', 'Filter results', 'Show less'], // English (US)
    'en-at': ['Show all', 'Filter results', 'Show less'], // English (US)
    es: ['Mostrar todo', 'Filtrar resultados', 'Mostrar menos'], // Spanish (Default)
    'es-es': ['Mostrar todo', 'Filtrar resultados', 'Mostrar menos'], // Spanish (Spain)
    'es-us': ['Mostrar todo', 'Filtrar resultados', 'Mostrar menos'], // Spanish (US)
    'fi-fi': ['Näytä kaikki', 'Suodata tulokset', 'Näytä vähemmän'], // Finnish (Finland)
    'fr-be': ['Tout afficher', 'Filtrer les résultats', 'Afficher moins'], // French (Belgium)
    'fr-ca': ['Tout afficher', 'Filtrer les résultats', 'Afficher moins'], // French (Canada)
    'fr-fr': ['Tout afficher', 'Filtrer les résultats', 'Afficher moins'], // French (France)
    'fr-ch': ['Tout afficher', 'Filtrer les résultats', 'Afficher moins'], // French (Switzerland)
    'fr-lu': ['Tout afficher', 'Filtrer les résultats', 'Afficher moins'], // French (Luxembourg)
    'it-ch': ['Mostra tutto', 'Filtra risultati', 'Mostra meno'], // Italian (Switzerland)
    'it-it': ['Mostra tutto', 'Filtra risultati', 'Mostra meno'], // Italian (Italy)
    'nb-no': ['Vis alle', 'Filtrer resultater', 'Vis mindre'], // Norwegian Bokmål (Norway)
    'nl-nl': ['Alles tonen', 'Resultaten filteren', 'Minder tonen'], // Dutch (Netherlands)
    'nl-be': ['Alles tonen', 'Resultaten filteren', 'Minder tonen'], // Dutch (Belgium)
    'pl-pl': ['Pokaż wszystko', 'Filtruj wyniki', 'Pokaż mniej'], // Polish (Poland)
    'pt-br': ['Mostrar tudo', 'Filtrar resultados', 'Mostrar menos'], // Portuguese (Brazil)
    'pt-pt': ['Mostrar tudo', 'Filtrar resultados', 'Mostrar menos'], // Portuguese (Portugal)
    'sv-se': ['Visa alla', 'Filtrera resultat', 'Visa mindre'], // Swedish (Sweden)
  };
  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=CategoryGridPage',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setPageTranslations(json);
      });
  }, []);

  const location = useLocation();

  let [translations, settranslations] = useState({ data: [] });
  let [showMore, setShowMore] = useState({});
  let param = useParams();
  let la;
  let arrla;
  let [showStuff, setshowstuff] = useState(999);
  const WrapperRef = useRef(null);
  let [PageTranslations, setPageTranslations] = useState({ data: [] });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (props.color == 'grey' && WrapperRef.current) {
      WrapperRef.current.style.backgroundColor = '#f2f3f4';
    }
  }, []);

  let arrla1 = '';

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  const toggleShowMore = (slug) => {
    setShowMore((prev) => ({ ...prev, [slug]: !prev[slug] }));
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [mobileActive, setMobileActive] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 860) {
      setMobileActive(true);
      setshowstuff(3);
      console.log('mobile');
    }
    if (window.innerWidth > 860) {
      setMobileActive(false);
      setshowstuff(999);
      if (document.getElementById('ProductCategoryGrid-filter')) {
        document.getElementById('ProductCategoryGrid-filter').style.display = 'flex !important';
      }
    }
  }, [windowSize]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function Card({ image, headline, links, slug, idattach }) {
    let filteredLinks = translations.data.filter((item) => item.attributes?.[slug] === true);
    filteredLinks = filteredLinks.sort((a, b) => b.attributes.Priority - a.attributes.Priority);
    let displayedLinks = showMore[slug] ? filteredLinks : filteredLinks.slice(0, 14);
    displayedLinks = filteredLinks.slice(0, 3);
    return (
      <div
        className="ProductCategoryGridCard CategoryGridPage-Card"
        id={showMore[slug] ? 'ActiveDiv' : ''}
        style={{ backgroundImage: `url(${image})` }}
      >
        {/*<img src={image} alt={headline} className="ProductCategoryGridCard-image" />*/}
        <a href={links} id={'ProductCategoryGrid-Mainlink-' + idattach.replace(/ /g, '-')}>
          <h3 className="ProductCategoryGridCard-headline">{headline}</h3>
        </a>
        <div className="ProductCategoryGridCard-links">
          {displayedLinks.map((item, index) => {
            return (
              <a
                target="_blank"
                key={index}
                href={item.attributes.Link}
                id={'ProductCategoryGrid-Sublink-' + item.attributes.Title.replace(/ /g, '-')}
                className="ProductCategoryGrid-Sublink"
              >
                {item.attributes.DisplayName}
              </a>
            );
          })}
          {filteredLinks.length > 14 && (
            <button
              id={showMore[slug] ? 'activeBtn' : ''}
              onClick={() => {
                toggleShowMore(slug);
                const cardElement = document.getElementById(slug);
                console.log(cardElement);
                let Btn = cardElement.querySelector('.ProductCategoryGrid-ShowMoreBtn');
                if (showMore[slug]) {
                  console.log('sdqweqw');
                  console.log(Btn);
                  cardElement.classList.add('triggeredDiv');
                  Btn.classList.add('triggeredBtn');
                } else {
                  cardElement.classList.remove('triggeredDiv');
                  Btn.classList.remove('triggeredBtn');
                }
              }}
              className="ProductCategoryGrid-ShowMoreBtn"
            >
              {showMore[slug] ? 'Show Less' : 'Show More'}
            </button>
          )}

          {filteredLinks.length > 3 && (
            <a href={links} target="_blank" className="CategoryGirdPage-LinkIntoShop">
              {translationsJson[param.count][0]}
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#667079" viewBox="0 0 256 256">
                <path d="M200,64V168a8,8,0,0,1-16,0V83.31L69.66,197.66a8,8,0,0,1-11.32-11.32L172.69,72H88a8,8,0,0,1,0-16H192A8,8,0,0,1,200,64Z"></path>
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  }

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/productcategories?locale=' +
        arrla1 +
        '&populate=CardImage,ResponsiveImage&pagination[pageSize]=250&sort[0]=Priority:desc',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        settranslations(json);
      });
  }, []);
  const [stackcount, setstackcount] = useState(0);
  return (
    <>
      <>
        <div className="Service-wrap" id="CategoryGridPage-Headline-wrap">
          <div className="Service-inner">
            <h2 id="ProductCatecoryGridBlock">
              {PageTranslations.data.length > 0 ? PageTranslations.data[0].attributes.FreeTitle1 : 'Product-categories'}
            </h2>
            <p id="CategoryGridPage-Headline-subheader">
              {PageTranslations.data.length > 0 ? PageTranslations.data[0].attributes.FreeText1 : ''}
            </p>
          </div>
        </div>
      </>

      <div className="ProductCategoryGrid-wrap" ref={WrapperRef}>
        <div className="ProductCategoryGrid-inner" id="ProductCategoryGrid-rework">
          {/*<div className="ProductCategoryGrid-GridFilter">
            <div className="systemgrid-head Service-inner">
              <div id="ProductCategoryGrid-filter-headline">
                <h3>{translationsJson[param.count][1]}</h3>
                <button
                  className={stackcount == 0 && 'ProductCategoryFilterActiveAdjust'}
                  id={ToggleIcon ? 'ProductCategoryFilterActive' : 'ProductCategoryFilterNotActive'}
                  onClick={() => {
                    setToggleIcon(!ToggleIcon);
                    if (stackcount == 0) {
                      if (document.getElementById('ProductCategoryFilterNotActive')) {
                        document.getElementById('ProductCategoryFilterNotActive').click();
                      }
                    }
                    setstackcount(stackcount + 1);

                    if (document.getElementById('ProductCategoryGrid-filter')) {
                      if (document.getElementById('ProductCategoryGrid-filter').style.display == 'none') {
                        document.getElementById('ProductCategoryGrid-filter').style.display = 'flex';
                      } else {
                        document.getElementById('ProductCategoryGrid-filter').style.display = 'none';
                      }
                    }
                  }}
                >
                  {ToggleIcon && stackcount > 0 ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#030303" viewBox="0 0 256 256">
                      <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                    </svg>
                  ) : !ToggleIcon && stackcount > 0 ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fefbfb" viewBox="0 0 256 256">
                      <path d="M230.6,49.53A15.81,15.81,0,0,0,216,40H40A16,16,0,0,0,28.19,66.76l.08.09L96,139.17V216a16,16,0,0,0,24.87,13.32l32-21.34A16,16,0,0,0,160,194.66V139.17l67.74-72.32.08-.09A15.8,15.8,0,0,0,230.6,49.53ZM40,56h0Zm106.18,74.58A8,8,0,0,0,144,136v58.66L112,216V136a8,8,0,0,0-2.16-5.47L40,56H216Z"></path>
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fefbfb" viewBox="0 0 256 256">
                      <path d="M230.6,49.53A15.81,15.81,0,0,0,216,40H40A16,16,0,0,0,28.19,66.76l.08.09L96,139.17V216a16,16,0,0,0,24.87,13.32l32-21.34A16,16,0,0,0,160,194.66V139.17l67.74-72.32.08-.09A15.8,15.8,0,0,0,230.6,49.53ZM40,56h0Zm106.18,74.58A8,8,0,0,0,144,136v58.66L112,216V136a8,8,0,0,0-2.16-5.47L40,56H216Z"></path>
                    </svg>
                  )}
                </button>
              </div>
             <div className="projectblock-filter CategoryGridPage-filter" id="ProductCategoryGrid-filter">
                <button className={activetoggle == 'all' ? 'activegridbtn' : ''} onClick={() => setactivetoggle('all')}>
                  {PageTranslations.data.length > 0 ? PageTranslations.data[0].attributes.FreeData1.data[0] : 'All'}
                </button>
                <button
                  className={activetoggle == 'FilterGlassrailBaseChannelsDisk' ? 'activegridbtn' : ''}
                  onClick={() => setactivetoggle('FilterGlassrailBaseChannelsDisk')}
                >
                  {PageTranslations.data.length > 0
                    ? PageTranslations.data[0].attributes.FreeData1.data[1]
                    : 'Glassrail basechannels disk'}
                </button>
                <button
                  className={activetoggle == 'FilterGlassrailBaseChannelsWedge' ? 'activegridbtn' : ''}
                  onClick={() => setactivetoggle('FilterGlassrailBaseChannelsWedge')}
                >
                  {PageTranslations.data.length > 0
                    ? PageTranslations.data[0].attributes.FreeData1.data[2]
                    : 'Glassrail basechannels wedge'}
                </button>
                <button
                  className={activetoggle == 'FilterGlassrailAdapters' ? 'activegridbtn' : ''}
                  onClick={() => setactivetoggle('FilterGlassrailAdapters')}
                >
                  {PageTranslations.data.length > 0
                    ? PageTranslations.data[0].attributes.FreeData1.data[3]
                    : 'Glassrail adapters'}
                </button>
                <button
                  className={activetoggle == 'FilterJulietBalconies' ? 'activegridbtn' : ''}
                  onClick={() => setactivetoggle('FilterJulietBalconies')}
                >
                  {PageTranslations.data.length > 0
                    ? PageTranslations.data[0].attributes.FreeData1.data[4]
                    : 'French balcony'}
                </button>
                <button
                  className={activetoggle == 'FilterHandrails' ? 'activegridbtn' : ''}
                  onClick={() => setactivetoggle('FilterHandrails')}
                >
                  {PageTranslations.data.length > 0
                    ? PageTranslations.data[0].attributes.FreeData1.data[5]
                    : 'Handrail'}
                </button>
                <button
                  className={activetoggle == 'FilterStainlessSteelPosts' ? 'activegridbtn' : ''}
                  onClick={() => setactivetoggle('FilterStainlessSteelPosts')}
                >
                  {PageTranslations.data.length > 0
                    ? PageTranslations.data[0].attributes.FreeData1.data[6]
                    : 'Stainless steel posts'}
                </button>
                <button
                  className={activetoggle == 'FilterGlassrailBaseClamps' ? 'activegridbtn' : ''}
                  onClick={() => setactivetoggle('FilterGlassrailBaseClamps')}
                >
                  {PageTranslations.data.length > 0
                    ? PageTranslations.data[0].attributes.FreeData1.data[7]
                    : 'Base clamps'}
                </button>
                <button
                  className={activetoggle == 'FilterAluminiumPosts' ? 'activegridbtn' : ''}
                  onClick={() => setactivetoggle('FilterAluminiumPosts')}
                >
                  {PageTranslations.data.length > 0
                    ? PageTranslations.data[0].attributes.FreeData1.data[8]
                    : 'Aluminium posts'}
                </button>
              </div>
            </div>
          </div>*/}
          <div>
            {translations.data.length > 0 && (
              <>
                <div className="product-category-grid" id="ProductCategoryGridId">
                  {translations.data.map((item, index) => {
                    let istheredata = '';
                    let slug = 'Nully';
                    if (item.attributes.Link) {
                      istheredata = item.attributes.Link;
                    }

                    if (index > showStuff) {
                      return <></>;
                    }
                    if (item.attributes.Slug) {
                      slug = item.attributes.Slug;
                    }
                    if (item.attributes.MainCategory == true && activetoggle == 'all') {
                      return (
                        <Card
                          key={slug}
                          image={
                            !mobileActive
                              ? item.attributes.CardImage.data.attributes.url
                              : item.attributes.ResponsiveImage.data.attributes.url
                          }
                          headline={item.attributes.DisplayName}
                          links={istheredata}
                          slug={slug}
                          idattach={item.attributes.Title}
                        />
                      );
                    }
                    if (item.attributes.MainCategory == true && item.attributes[activetoggle] == true) {
                      return (
                        <Card
                          key={slug}
                          image={
                            !mobileActive
                              ? item.attributes.CardImage.data.attributes.url
                              : item.attributes.ResponsiveImage.data.attributes.url
                          }
                          headline={item.attributes.DisplayName}
                          links={istheredata}
                          slug={slug}
                          idattach={item.attributes.Title}
                        />
                      );
                    }
                  })}
                </div>
                <div
                  id="ProductCategoryGrid-ShowMoreRespDiv"
                  className={showStuff != 3 ? 'ProductCategoryGrid-ShowMoreItemsBtnActive' : ''}
                >
                  {showStuff === 3 && <div id="ProductCategoryGrid-RespOverlay"></div>}
                  <button
                    id="ProductCategoryGrid-ShowMoreItemsBtn"
                    onClick={() => {
                      if (showStuff == 3) {
                        setshowstuff(999);
                      } else {
                        setshowstuff(3);
                      }
                    }}
                  >
                    {showStuff == 3 ? translationsJson[param.count][0] : translationsJson[param.count][2]}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCategoryGrid;
