// dnd-system/DropableContainer.jsx
import React, { useContext, useRef, useState, useEffect } from 'react';
import { DnDContext } from './drag-and-drop-context';
import { ContainerProvider } from './drag-and-drop-container-context';

/**
 * DropableContainer
 * A container that accepts dropped items.
 * It uses the global drag and drop context to handle item placement and preview.
 * Events:
 * - onContainerEnter: fired when a dragged item first enters the container.
 * - onContainerLeave: fired when a dragged item leaves the container.
 * - onItemDropped: fired when an item is successfully dropped in this container.
 * - onChange: fired when the items in this container change.
 */
const DropableContainer = ({ children, items = [], onChange, onContainerEnter, onContainerLeave, onItemDropped }) => {
  const { draggedItem, previewInfo, setPreviewInfo, draggedItemOrigin, setDraggedItem, setDraggedItemOrigin, onDrop } =
    useContext(DnDContext);

  const containerRef = useRef(null);
  const [containerId] = useState(() => Math.random().toString(36).substring(2, 9));
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    // If there's no dragged item, reset hover state
    if (!draggedItem) {
      setIsHovered(false);
    }
  }, [draggedItem]);

  const handleDragOver = (e) => {
    if (!draggedItem) return;
    e.preventDefault();
    const rect = containerRef.current.getBoundingClientRect();
    const offsetY = e.clientY - rect.top;

    // Find the position to insert the preview element
    const draggableChildren = findDraggableItemsReact(children);
    let newIndex = items.length;

    // For each draggable item, check if we should insert before it
    for (let i = 0; i < draggableChildren.length; i++) {
      const childElement = containerRef.current.querySelectorAll('.draggable-item')[i];
      if (childElement) {
        const childRect = childElement.getBoundingClientRect();
        if (offsetY < childRect.top - rect.top + childRect.height / 2) {
          newIndex = i;
          break;
        }
      }
    }

    setPreviewInfo({ containerId, index: newIndex });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (!draggedItem) return;
    if (previewInfo && previewInfo.containerId === containerId) {
      const newItems = [...items.filter((i) => i !== draggedItem.data)];
      newItems.splice(previewInfo.index, 0, draggedItem.data);

      // Remove item from origin list if applicable
      if (draggedItemOrigin && draggedItemOrigin.onChange && draggedItemOrigin.items) {
        const originItems = draggedItemOrigin.items.filter((i) => i !== draggedItem.data);
        draggedItemOrigin.onChange(originItems);
      }

      onChange && onChange(newItems);
      onItemDropped && onItemDropped(draggedItem.data);

      // Global onDrop event
      onDrop && onDrop();
    }

    resetDragState();
  };

  const handleDragEnter = () => {
    if (!draggedItem) return;
    // onDragEnter can be fired multiple times. We only fire onContainerEnter when truly entering.
    if (!isHovered) {
      setIsHovered(true);
      onContainerEnter && onContainerEnter();
    }
  };

  const handleDragLeave = (e) => {
    if (!draggedItem) return;
    // Check if we left the container entirely
    if (!containerRef.current.contains(e.relatedTarget)) {
      setIsHovered(false);
      setPreviewInfo(null);
      onContainerLeave && onContainerLeave();
    }
  };

  function resetDragState() {
    setDraggedItem(null);
    setDraggedItemOrigin(null);
    setPreviewInfo(null);
  }

  // Insert preview-element into the children structure at the correct position
  const finalChildren = insertPreviewElement(children, previewInfo, containerId, items);

  return (
    <ContainerProvider items={items} onChange={onChange}>
      <div
        className="dropable-container"
        ref={containerRef}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {finalChildren}
      </div>
    </ContainerProvider>
  );
};

function insertPreviewElement(children, previewInfo, containerId, items) {
  if (!previewInfo || previewInfo.containerId !== containerId) {
    return children;
  }

  const { index } = previewInfo;

  let draggableCount = 0;

  function mapChildrenWithPreview(childrenNode) {
    return React.Children.map(childrenNode, (child) => {
      if (!React.isValidElement(child)) return child;
      // If this is a DraggableItem with an item prop, increment count
      const hasItem = child.props && child.props.item !== undefined;

      const mappedChild = React.cloneElement(child, {
        children: mapChildrenWithPreview(child.props.children),
      });

      if (hasItem) {
        // Before rendering this item, check if we should insert preview-element
        if (draggableCount === index) {
          draggableCount++;
          return [<div key="preview" className="preview-element" />, mappedChild];
        }
        draggableCount++;
        return mappedChild;
      }
      // Not a draggable item or no item prop, just return mappedChild
      return mappedChild;
    });
  }

  let result = mapChildrenWithPreview(children);

  // If index == items.length, insert preview-element at the end
  if (previewInfo && previewInfo.containerId === containerId && index === items.length) {
    result = React.Children.toArray(result);
    result.push(<div key="preview-end" className="preview-element" />);
  }

  return result;
}

/**
 * findDraggableItemsReact
 * Returns an array of DraggableItem elements (React elements) that have an `item` prop.
 */
function findDraggableItemsReact(children) {
  const result = [];
  function traverse(c) {
    React.Children.forEach(c, (child) => {
      if (React.isValidElement(child)) {
        if (child.type && child.type.displayName === 'DraggableItem' && child.props.item !== undefined) {
          result.push(child);
        }
        if (child.props && child.props.children) {
          traverse(child.props.children);
        }
      }
    });
  }
  traverse(children);
  return result;
}

export default DropableContainer;
